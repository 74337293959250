import "./assets/main.css"

const Footer = () => {



    return (
        <div className="Footer">Copyright © 2023 - { new Date().getFullYear() } · All rights reserved</div>
    )

}

export default Footer
